import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Check } from '@mui/icons-material';
import { Box, Button, Card, Chip, Divider, Radio, Stack, Typography } from '@mui/material';

import { useActiveSubscriptionInfo } from 'shared/api/hooks/billing/useActiveSubscriptionInfo';
import { RoutesEnum } from 'shared/enums';
import { getDaysLeftV2, getPriceInEuros } from 'shared/helpers/billingHelpers';
import { useBillingModal } from 'shared/hooks/useBillingModal';
import { useGetUser } from 'shared/hooks/useGetUser';
import { Product } from 'shared/models/billing';

import classNames from 'classnames';

import styles from './PlanItemV2.module.scss';

interface PlanItemProps {
	item: Product;
	interval: 'month' | 'year';
	selectedPlanId?: string;
	billingInterval: 'month' | 'year';
}

const includes = [
	'Saving Ads via Chrome extension to AdUniverse.ai',
	'Organizing Ads within Tag folders',
	'FB Brands tracking',
	'Discovering Ads saved by human',
	'Team collaboration',
];

const PlanItemV2: FC<PlanItemProps> = ({ item, interval, selectedPlanId, billingInterval }) => {
	const { handleModalClose } = useBillingModal();
	const navigate = useNavigate();
	const { data: user } = useGetUser();
	const {
		isSubscriptionActive,
		isSubscriptionTrialing,
		activeSubscription,
		subscriptionEndsAt,
		isTrialCanceled,
		isError,
		cancelSubscriptionAt,
	} = useActiveSubscriptionInfo(user?.id);
	const isSelected = item.id === selectedPlanId;

	if (!user) return null;

	const handleNavigateToCheckout = () => {
		handleModalClose();
		if (!isSubscriptionActive || (isSubscriptionTrialing && isTrialCanceled) || isError) {
			navigate(RoutesEnum.SUBSCRIPTION_CONFIRMATION, { state: { plan: item } });
		} else {
			navigate(RoutesEnum.CHECKOUT, { state: { plan: item } });
		}
	};

	const isPro = item.name === 'Pro plan';
	const chipLabel =
		selectedPlanId === item.id && subscriptionEndsAt && isSubscriptionTrialing
			? getDaysLeftV2(subscriptionEndsAt)
			: isPro
				? 'popular'
				: '';

	const getButtonLabel = () => {
		if (!selectedPlanId || isSubscriptionTrialing) {
			return 'Subscribe';
		}
		const currentPrice = activeSubscription?.price
			? getPriceInEuros([activeSubscription?.price], billingInterval)
			: '0';

		if (getPriceInEuros(item.prices, billingInterval) < currentPrice) {
			return 'Downgrade';
		} else if (getPriceInEuros(item.prices, billingInterval) > currentPrice) {
			return 'Upgrade';
		}

		return 'Your current plan';
	};

	const getSubscriptionInfo = () => {
		if (isSubscriptionActive) {
			if (cancelSubscriptionAt) {
				if (item.id === activeSubscription?.plan.product) {
					return `Subscription canceled, valid until ${new Date(cancelSubscriptionAt * 1000).toLocaleDateString()}`;
				}
			} else {
				if (item.id === activeSubscription?.plan.product) {
					return subscriptionEndsAt
						? `Next invoice on ${new Date(subscriptionEndsAt * 1000).toLocaleDateString()}`
						: '';
				}
			}
		}
		return '';
	};

	return (
		<Card className={classNames(styles.container, { [styles.selected]: isSelected })}>
			<Box
				className={styles.titleContainer}
				alignItems="center"
				justifyContent="space-between"
				width="100%"
				mb={1}
			>
				<Box display="flex" gap="16px">
					<Typography
						variant="h4"
						className={classNames(
							isSubscriptionTrialing ? styles.freeTrial : styles.default,
							{ [styles.activeTitle]: isSelected }
						)}
					>
						{item.name}
					</Typography>
					{selectedPlanId === item.id && isSubscriptionTrialing && (
						<Chip label="Free Trial" />
					)}
				</Box>
				<Radio
					checked={isSelected}
					icon={
						<Box
							sx={{
								width: '20px',
								height: '20px',
								borderRadius: '50%',
								border: `2px solid #CED4DA`,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								bgcolor: 'transparent',
							}}
						>
							<Box
								sx={{
									width: '12px',
									height: '12px',
									borderRadius: '50%',
									bgcolor: 'transparent',
								}}
							/>
						</Box>
					}
					checkedIcon={
						<Box
							sx={{
								width: '20px',
								height: '20px',
								borderRadius: '50%',
								border: `2px solid #3B82F6`,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								bgcolor: '#009EC2',
							}}
						>
							<Box
								sx={{
									width: '12px',
									height: '12px',
									borderRadius: '50%',
									bgcolor: '#FFFFFF',
								}}
							/>
						</Box>
					}
				/>
			</Box>
			<Box height={48}>
				<Typography variant="body2">{item.description}</Typography>
			</Box>
			<Divider className={styles.divider} />
			<Box mb={3} mt={3}>
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h4">{`€${getPriceInEuros(item.prices, interval)}/mo`}</Typography>
					{chipLabel && (
						<Chip
							label={chipLabel}
							className={
								isSubscriptionTrialing ? styles.freeTrialChip : styles.proChip
							}
						/>
					)}
				</Stack>
				<Typography variant="body2">
					{item.name === 'Free trial'
						? 'No money, no issue'
						: interval === 'month'
							? 'Billed monthly'
							: 'Billed yearly'}
				</Typography>
			</Box>
			<Typography variant="h5" fontWeight={600}>
				Includes:
			</Typography>
			<Box>
				{includes.map((value, index) => (
					<Stack key={index} direction="row" alignItems="center" gap={1}>
						<Check className={styles.icon} />
						<Typography variant="body1" fontWeight={500}>
							{value}
						</Typography>
					</Stack>
				))}
				<Stack direction="row" alignItems="center" gap={1}>
					<Check className={styles.icon} />
					<Typography variant="body1" fontWeight={600}>
						{`${item.metadata?.teamMembers} team seats available*`}
					</Typography>
				</Stack>
				<Stack direction="row" alignItems="center" gap={1}>
					<Check className={styles.icon} />
					<Typography variant="body1" fontWeight={600}>
						{`${item.metadata?.brands} brands available*`}
					</Typography>
				</Stack>
			</Box>
			<Typography className={styles.subInfo} variant="subtitle2" color="primary">
				{getSubscriptionInfo()}
			</Typography>
			{item.name !== 'Free trial' ? (
				<Box marginTop={8}>
					<Button
						variant="contained"
						className={styles.btn}
						disabled={isSelected && !isSubscriptionTrialing}
						onClick={handleNavigateToCheckout}
					>
						{getButtonLabel()}
					</Button>
				</Box>
			) : null}
		</Card>
	);
};

export default PlanItemV2;
